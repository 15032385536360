import bigBrotherDb from '@/bigBrotherApi'

const state = () => ({
  sensors: {},
  statusIntervalIds: []
})

const getters = {
  getSensorById: (state) => (sensorId) => {
    return state.sensors[sensorId]
  }
}

const actions = {
  getNewSensorStatus ({ commit, state, rootState}) {
    bigBrotherDb.getCurrentSensorData(rootState.token).then(sensorStatuses => {
      sensorStatuses.forEach(status => {
        // this ensures that the sensor exists before we try to update the status
        commit('sensorCheck', status.imei)

        commit('updateSensorStatus', {
          imei: status.imei,
          status: status
        })
      })
    })
  },
  getNewSensorDetails: async function ({ commit, state, rootState }, { imei }) {
    // this ensurs that the sensor exists before we try to update the details
    commit('sensorCheck', imei)

    if (state.sensors[imei].lastQueriedDates.length === 0) {
      // means this has never been queried before, set up dates initially
      const end = new Date()
      const start = new Date(end.getTime())
      // FIXME: make this dynamic based on date ranges of graph
      start.setHours(start.getHours() - 168)

      commit('updateSensorDates', {
        imei: imei,
        lastQueriedDates: [start, end]
      })
    } else {
      // means this has been queried before, update query params
      const newStart = state.sensors[imei].lastQueriedDates[1]
      // get 5 minutes before last end to ensure we have all the data
      newStart.setMinutes(newStart.getMinutes() - 5)

      const newEnd = new Date()
      commit('updateSensorDates', {
        imei:  imei,
        lastQueriedDates: [newStart, newEnd]
      })
    }
    const start = state.sensors[imei].lastQueriedDates[0]
    const end = state.sensors[imei].lastQueriedDates[1]

    const data = await bigBrotherDb.getSensorStatus(start, end, imei, rootState.token)
    const realDataToAdd = []
    let pointsToCheck = 10
    if (state.sensors[imei].specificData.length < 10) {
      pointsToCheck = state.sensors[imei].specificData.length
    }

    data.forEach(newPoint => {
      let found = false
      for (let i = 0; i < pointsToCheck; i++) {
        if (newPoint.gpsUTCTime === state.sensors[imei].specificData[i].gpsUTCTime) {
          found = true
        }
      }
      if (found === false) {
        realDataToAdd.push(newPoint)
      }
    })

    commit('addNewSpecificData', {
      imei: imei,
      dataToAdd: realDataToAdd
    })
    return 'success'
  }
}

const mutations = {
  sensorCheck (state, imei) {
    if (!Object.prototype.hasOwnProperty.call(state.sensors, imei)) {
      // means the sensor hasn't been registered, register it now
      const newSensor = {
        imei: imei,
        status: null,
        specificData: [],
        lastQueriedDates: [],
        whatToGraph: 'note_temp',
        dateRangeToGraph: 'week1',
        showGraphPoints: true,
        detailsIntervalIds: []
      }
      state.sensors[imei] = newSensor
    }
  },
  updateSensorStatus (state, {imei, status}) {
    state.sensors[imei].status = status
  },
  updateSensorDates (state, {imei, lastQueriedDates }) {
    state.sensors[imei].lastQueriedDates = lastQueriedDates
  },
  addNewSpecificData (state, { imei, dataToAdd }) {
    state.sensors[imei].specificData.unshift(...dataToAdd)
  },
  updateWhatToGraph (state, { imei, whatToGraph }) {
    state.sensors[imei].whatToGraph = whatToGraph
  },
  updateDateRangeToGraph(state, { imei, dateRangeToGraph }) {
    state.sensors[imei].dateRangeToGraph = dateRangeToGraph
  },
  updateShowGraphPoints (state, { imei, showGraphPoints }) {
    state.sensors[imei].showGraphPoints = showGraphPoints
  },
  addDetailsIntervalId (state, {imei, intervalId }) {
    state.sensors[imei].detailsIntervalIds.push(intervalId)
  },
  addStatusIntervalId (state, intervalId) {
    state.statusIntervalIds.push(intervalId)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}