import bigBrotherDb from "@/bigBrotherApi";

const state = () => ({
  logEntries: {},
});

function initializeFieldServiceLogEntry(dbFieldServiceLog) {
  const newFieldServiceLogEntry = {
    _id: dbFieldServiceLog._id,
    Log_Timestamp: dbFieldServiceLog.Log_Timestamp,
    Log_Emp_Name: dbFieldServiceLog.Log_Emp_Name,
    Gen_Status: dbFieldServiceLog.Gen_Status,
    Tank1_Level: dbFieldServiceLog.Tank1_Level,
    Tank2_Level: dbFieldServiceLog.Tank2_Level,
    Tank3_Level: dbFieldServiceLog.Tank3_Level,
    Tank4_Level: dbFieldServiceLog.Tank4_Level,
    Sampler_1: dbFieldServiceLog.Sampler_1,
    Sampler_2: dbFieldServiceLog.Sampler_2,
    Sampler_3: dbFieldServiceLog.Sampler_3,
    Log_Notes: dbFieldServiceLog.Log_Notes,
  };

  return newFieldServiceLogEntry;
}

const actions = {
  async initializeFieldServiceLogs({ commit, state, rootState }) {
    const dbAllFieldServiceLogs = await bigBrotherDb.getAllFieldServiceLogs(
      rootState.token
    );
    for (let dbLogEntry of dbAllFieldServiceLogs) {
      const logEntry = initializeFieldServiceLogEntry(
        dbLogEntry
      );
      commit("addFieldServiceLogEntry", {
        logEntry: logEntry,
      });
    }
  },
};

const mutations = {
  updateFieldServiceLogEntries(state, payload) {
    state.fieldServiceLogEntries = payload.fieldServiceLogEntries;
  },
  addFieldServiceLogEntry(state, { logEntry }) {
    state.logEntries[logEntry._id] = logEntry;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
