<template>
  <nav class="navbar is-black" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <div v-if="this.loggedIn">
        <router-link v-if="this.$store.state.admin" id="navbar-brand-link" class="navbar-item" to="/manage/projects">
          <img src="./assets/MoranLogo3.png" alt="Moran Logo 3">
        </router-link>
        <router-link v-else id="navbar-brand-link" class="navbar-item" :to="this.nonAdminLinks[0].path + this.currentProjectId">
          <!-- <img src="./assets/MoranLogo1.png" alt="Moran Logo 1"> -->
          <!-- <img src="./assets/MoranLogo2.png" alt="Moran Logo 2"> -->
          <img src="./assets/MoranLogo3.png" alt="Moran Logo 3">
        </router-link>
      </div>
      <div v-else>
        <router-link id="navbar-brand-link" class="navbar-item" to="/manage/projects">
            <img src="./assets/MoranLogo3.png" alt="Moran Logo 3">
          </router-link>
      </div>
      <a role="button" class="navbar-burger has-text-light" aria-label="menu" aria-expanded="false" data-target="navbarMainMenu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbarMainMenu" class="navbar-menu has-background-black">
      <div v-if="this.loggedIn" v-for="links in navLinks" class="navbar-start has-text-light has-background-black" style="margin-right: 0;">
        <!-- <router-link v-if="this.$store.state.admin" class="navbar-item has-text-light" :to="links.path">{{ links.name }}</router-link> -->
        <router-link v-if="this.admin" class="navbar-item has-text-light" :to="links.path">{{ links.name }}</router-link>
        <router-link v-else class="navbar-item has-text-light" :to="links.path + this.currentProjectId">{{ links.name }}</router-link>
      </div>
    </div>
    <!-- <div class="checkbox navbar-end">
      <input type="checkbox" id="admin" value="admin" name="admin" @change="toggleShowAdmin">Admin
    </div> -->
    <div class="navbar-end" v-if="loggedIn">
      <div class="navbar-item has-dropdown is-hoverable">
        <img id="account-img" src="../src/assets/accountIcon.png" alt="profileIcon">
        <div class="navbar-dropdown is-right">
          <p class="navbar-item">{{username}}</p>
          <hr class="navbar-divider">
          <a class="navbar-item" @click="logout">Logout</a>
        </div>
      </div>
    </div>
    <div class="navbar-end" v-else>
      <a class="navbar-item has-text-light" @click="logout">Login</a>
    </div>
  </nav>
  <router-view/>
</template>

<style>
#account-img {
  margin: 0.75rem;
}
</style>

<script>
import { mapState, mapMutations } from 'vuex'
// import state from './store/index'
import bigBrotherDb from '@/bigBrotherApi';

export default {
  computed: {
    ...mapState(['loggedIn', 'username', 'currentProjectId', 'admin', 'token']),
    navLinks: function () {
      if (this.admin) {
        return this.adminLinks
      } else {
        return this.nonAdminLinks
      }
    }
  },
  data() {
    return {
      // admin: false,
      adminLinks: [
        { name: 'Projects', path: '/manage/projects' },
        { name: 'Geo-Fences', path: '/manage/geofences' },
        { name: 'Global Map', path: '/globalMap' },
        { name: 'Trailer Status', path: '/monitors/powertrailerstatus' },
        // { name: 'Pi Monitor Status', path: '/monitors/pimonitorstatus' },
        { name: 'Weather Status', path: '/monitors/weatherstatus' },
        { name: 'Sensor Status', path: '/monitors/sensorstatus' },
        { name: 'Site Configs', path: '/manage/siteConfigs'}
        // { name: 'Corkboard', path: '/manage/corkboard' },
        // { name: 'Field Service Log', path: '/manage/fieldServiceLog' }
      ],
      nonAdminLinks: [
        { name: 'Dashboard', path: '/dashboard/'},
        { name: 'Replay View', path: '/project/replay/'},
        { name: 'Status View', path: '/project/status/'},
        { name: 'Report View', path: '/project/report/'}
      ]
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.logoutAuto)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.logoutAuto)
  },
  methods: {
    ...mapMutations([
      'updateLogin'
    ]),
    logout: async function () {
      await bigBrotherDb.logout(this.token)

      this.updateLogin({
        loggedIn: false,
        token: '',
        username: '',
        roles: [],
        currentProjectId: ''
      })
      this.$router.replace('/')
    },
    logoutAuto: function () {
      bigBrotherDb.logout(this.token)
    },
    navToLogin: function () {
      this.$router.replace('/')
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Add a click event on each of them
  $navbarBurgers.forEach(el => {
    el.addEventListener('click', () => {

      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');

      // Close the navbar menu when a navbar item is clicked
      const $navbarItems = Array.prototype.slice.call(document.querySelectorAll('.navbar-item'), 0);
      $navbarItems.forEach(el => {
        el.addEventListener('click', () => {
          el.classList.remove('is-active');
          $target.classList.remove('is-active');
          $navbarBurgers.forEach(el => {
            el.classList.remove('is-active');
          });
        });
      });

    });
  });

});
</script>
