import bigBrotherDb from '@/bigBrotherApi'

const state = () => ({
  powerTrailers: {},
  statusIntervalIds: []
})

const actions = {
  getNewPowerTrailerStatus ({ commit, state, rootState }) {
    bigBrotherDb.getPowerTrailerSummary(rootState.token).then(trailerStatuses => {
      trailerStatuses.forEach(status => {
        // this ensures that the power trailer exists before we try to update the status
        commit('trailerCheck', status.Trailer_Id)

        commit('updateTrailerStatus', {
          trailerId: status.Trailer_Id,
          status: status
        })
      })
    })
  },
  getNewTrailerDetails: async function ({ commit, state, rootState }, { trailerId }) {
    // this ensures that the power trailer exists before we try to update the details
    commit('trailerCheck', trailerId)

    if (state.powerTrailers[trailerId].lastQueriedDates.length === 0) {
      // means this has never been queried before, set up dates initially
      const end = new Date()
      const start = new Date(end.getTime())
      // FIXME: make this dynamic based on date ranges of graph
      start.setHours(start.getHours() - 168)

      commit('updateTrailerDates', {
        trailerId: trailerId,
        lastQueriedDates: [start, end]
      })
    } else {
      // means this has been queried before, update query params
      const newStart = state.powerTrailers[trailerId].lastQueriedDates[1]
      // get 5 minutes before last end to ensure we have all the data
      newStart.setMinutes(newStart.getMinutes() - 5)

      const newEnd = new Date()
      commit('updateTrailerDates', {
        trailerId: trailerId,
        lastQueriedDates: [newStart, newEnd]
      })
    }
    const start = state.powerTrailers[trailerId].lastQueriedDates[0]
    const end = state.powerTrailers[trailerId].lastQueriedDates[1]

    const data = await bigBrotherDb.getPowerTrailerStatus(start, end, trailerId, rootState.token)
    const realDataToAdd = []
    let pointsToCheck = 10
    if (state.powerTrailers[trailerId].specificData.length < 10) {
      pointsToCheck = state.powerTrailers[trailerId].specificData.length
    }

    data.forEach(newPoint => {
      let found = false
      for (let i = 0; i < pointsToCheck; i++) {
        if (newPoint.Time_Received === state.powerTrailers[trailerId].specificData[i].Time_Received) {
          found = true
        }
      }
      if (found === false) {
        realDataToAdd.push(newPoint)
      }
    })

    commit('addNewSpecificData', {
      trailerId: trailerId,
      dataToAdd: realDataToAdd
    })
    return 'success'
  }
}

const mutations = {
  trailerCheck (state, trailerId) {
    if (!Object.prototype.hasOwnProperty.call(state.powerTrailers, trailerId)) {
      // means the trailer hasn't been registered, register it now
      const newTrailer = {
        trailerId: trailerId,
        status: null,
        specificData: [],
        lastQueriedDates: [],
        whatToGraph: 'battVoltage',
        dateRangeToGraph: 'week1',
        showGraphPoints: true,
        detailsIntervalIds: []
      }
      state.powerTrailers[trailerId] = newTrailer
    }
  },
  updateTrailerStatus (state, { trailerId, status }) {
    state.powerTrailers[trailerId].status = status
  },
  updateTrailerDates (state, { trailerId, lastQueriedDates }) {
    state.powerTrailers[trailerId].lastQueriedDates = lastQueriedDates
  },
  addNewSpecificData (state, { trailerId, dataToAdd }) {
    state.powerTrailers[trailerId].specificData.unshift(...dataToAdd)
  },
  updateWhatToGraph (state, { trailerId, whatToGraph }) {
    state.powerTrailers[trailerId].whatToGraph = whatToGraph
  },
  updateDateRangeToGraph (state, { trailerId, dateRangeToGraph }) {
    state.powerTrailers[trailerId].dateRangeToGraph = dateRangeToGraph
  },
  updateShowGraphPoints (state, { trailerId, showGraphPoints }) {
    state.powerTrailers[trailerId].showGraphPoints = showGraphPoints
  },
  addDetailsIntervalId (state, { trailerId, intervalId }) {
    state.powerTrailers[trailerId].detailsIntervalIds.push(intervalId)
  },
  addStatusIntervalId (state, intervalId) {
    state.statusIntervalIds.push(intervalId)
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
