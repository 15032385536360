<template>
  <div class="container">
    <h1 class="title has-text-centered">Moran Status Login</h1>
    <div class="card">
      <form class="form" v-on:submit.prevent="login">
        <div class="field">
          <label class="label">Username</label>
          <div class="control">
            <input v-model="username" type="text" class="input">
          </div>
        </div>
        <div class="field">
          <label class="label">Password</label>
          <div class="control">
            <input v-model="password" type="password" class="input">
          </div>
        </div>
        <div class="field">
          <div class="control">
            <button class="button is-link">Login</button>
          </div>
        </div>
        <div class="field">
          <label class="label has-background-danger has-text-white" :class="{ 'is-hidden': login_no_error }">Login Failed!</label>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import bigBrotherDb from '@/bigBrotherApi'

export default {
  name: 'Login',
  data: function () {
    return {
      username: '',
      password: '',
      login_no_error: true
    }
  },
  computed: {
    ...mapState(['loggedIn', 'admin', 'currentProjectId'])
  },
  methods: {
    ...mapMutations([
      'updateLogin'
    ]),
    login: function () {
      bigBrotherDb.login(this.username, this.password).then(response => {
        if (response.login) {
          this.updateLogin({
            loggedIn: response.login,
            token: response.token,
            username: response.username,
            roles: response.roles,
            currentProjectId: response.currentProjectId
          })
          if (this.admin) {
            this.$router.replace('/manage/projects')
          } else {
            this.navDashboard(this.currentProjectId)
          }
        } else {
          this.updateLogin({
            loggedIn: response.login,
            token: '',
            username: '',
            roles: [],
            currentProjectId: null
          })
          this.login_no_error = false
        }
      })
    },
    navDashboard: function (id) {
      this.$router.push({
        name: 'Dashboard',
        params: { id: id }
      })
    },
  },
  mounted: function () {
    if (this.loggedIn) {
      if (this.admin) {
        this.$router.replace('/manage/projects')
      } else {
        this.navDashboard(this.currentProjectId)
      }
    }
  }
}
</script>
