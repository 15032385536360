import bigBrotherDb from '@/bigBrotherApi'
import dateHelp from '@/dateHelperFunctions'
import * as turf from '@turf/turf'

function isInside (location, geofence) {
  let result = false
  geofence.fences.forEach(fence => {
    if (turf.booleanPointInPolygon(location, fence)) {
      result = true
    }
  })
  return result
}

function setUpTeam (dbTeam) {
  // takes in a team object from the database, turns it into
  // the format needed for use here, and returns it

  return {
    id: dbTeam.id,
    name: dbTeam.name,
    color: dbTeam.color,
    trackers: {}
  }
}

function setUpTracker (dbTracker) {
  // takes in a tracker object from the database, turns it into
  // the format needed for use here, and returns it

  return {
    id: dbTracker.id,
    name: dbTracker.name,
    imei: dbTracker.imei,
    type: dbTracker.type,
    locations: [],
    currentLocation: null,
    currentGeofences: [],
    onSite: false,
    team: dbTracker.team,
    color: dbTracker.color,
    events: [],
    fenceTotals: {},
    status: {}
  }
}

function setUpGeofence (dbGeofence) {
  // takes in a geofence object from the database, turns it into
  // the format needed for use here, and returns it
  return {
    id: dbGeofence._id,
    name: dbGeofence.name,
    fences: dbGeofence.fences,
    currentTrackers: [],
    events: [],
    trackerTotals: {},
    status: {}
  }
}

function initializeGlobalProject (dbTrackers) {
  const globalStartDate = new Date(2021, 3)
  const globalEndDate = new Date(2025, 1)

  const newProject = {
    projectId: 'Global',
    name: 'Global',
    startDate: globalStartDate,
    endDate: globalEndDate,
    teams: {},
    trackers: {},
    trackerImeis: [],
    geofences: {},
    onSiteGeofence: null,
    queryIntervalIds: [],
    lastQueriedDates: [],
    currentLocations: [],
    currentGeneratorMessages: [],
    siteConfig: null,
    fieldServiceLogs: [],
    newDataLoaded: 0,
    lastUpdated: null,
    allMessageIdsProcessed: new Set()
  }

  dbTrackers.forEach(dbTracker => {
    dbTracker.color = 'Red'
    dbTracker.name = dbTracker.id
    const tracker = setUpTracker(dbTracker)
    newProject.trackers[tracker.imei] = tracker
    newProject.trackerImeis.push(tracker.imei)
  })

  return newProject
}

async function initializeProject (dbProject, state, token) {
  const projectStartDate = dateHelp.dateObjFromDbDate(dbProject.startDate)
  const projectEndDate = dateHelp.dateObjFromDbDate(dbProject.endDate)

  const newProject = {
    projectId: dbProject._id,
    name: dbProject.name,
    startDate: projectStartDate,
    endDate: projectEndDate,
    teams: {},
    trackers: {},
    trackerImeis: [],
    geofences: {},
    onSiteGeofence: dbProject.onSiteGeofence,
    queryIntervalIds: [],
    lastQueriedDates: [],
    currentLocations: [],
    currentGeneratorMessages: [],
    siteConfig: null,
    fieldServiceLogs: [],
    newDataLoaded: 0,
    lastUpdated: null,
    allMessageIdsProcessed: new Set()
  }

  if (dbProject.siteConfig != null) {
    if (state.siteConfigs[dbProject.siteConfig] != null) {
      newProject.siteConfig = state.siteConfigs[dbProject.siteConfig]
    }
  }

  dbProject.teams.forEach(dbTeam => {
    newProject.teams[dbTeam.id] = setUpTeam(dbTeam)
  })

  dbProject.trackers.forEach(dbTracker => {
    const tracker = setUpTracker(dbTracker)
    newProject.trackers[tracker.imei] = tracker
    newProject.trackerImeis.push(tracker.imei)
  })

  const geofenceIdList = dbProject.geofences
  if (dbProject.onSiteGeofence != null) {
    geofenceIdList.push(dbProject.onSiteGeofence)
  }

  // const geofences = []

  // geofenceIdList.forEach(id => {
  //   geofences.push(state.geofences[id])
  // })

  // for (let geofenceId of geofenceIdList) {
  //   geofences.push(state.geofences[geofenceId])
  // }

  // const geofences = await bigBrotherDb.getGeofencesByIds(geofenceIdList, token)

  // geofences.forEach(dbGeofence => {
  //   newProject.geofences[dbGeofence._id] = setUpGeofence(dbGeofence)
  // })

  geofenceIdList.forEach(id => {
    newProject.geofences[id] = setUpGeofence(state.geofences[id])
  })

  Object.values(newProject.trackers).forEach(tracker => {
    Object.values(newProject.geofences).forEach(geofence => {
      tracker.status[geofence.id] = {
        fenceId: geofence.id,
        time: null,
        inside: false
      }

      tracker.fenceTotals[geofence.id] = {
        fenceId: geofence.id,
        fenceName: geofence.name,
        totalTime: 0,
        timeInsideFormatted: ''
      }

      geofence.status[tracker.imei] = {
        trackerImei: tracker.imei,
        trackerName: tracker.name,
        time: null,
        inside: false
      }

      geofence.trackerTotals[tracker.imei] = {
        trackerImei: tracker.imei,
        trackerName: tracker.name,
        totalTime: 0,
        timeInsideFormatted: ''
      }
    })
  })

  return newProject
}

function initializeFieldServiceLogEntry(dbFieldServiceLog) {
  const newFieldServiceLogEntry = {
    _id: dbFieldServiceLog._id,
    projectId: dbFieldServiceLog.projectId,
    Log_Timestamp: dbFieldServiceLog.Log_Timestamp,
    Log_Emp_Name: dbFieldServiceLog.Log_Emp_Name,
    Gen_Status: dbFieldServiceLog.Gen_Status,
    Tank1_Level: dbFieldServiceLog.Tank1_Level,
    Tank2_Level: dbFieldServiceLog.Tank2_Level,
    Tank3_Level: dbFieldServiceLog.Tank3_Level,
    Tank4_Level: dbFieldServiceLog.Tank4_Level,
    Sampler_1: dbFieldServiceLog.Sampler_1,
    Sampler_2: dbFieldServiceLog.Sampler_2,
    Sampler_3: dbFieldServiceLog.Sampler_3,
    Log_Notes: dbFieldServiceLog.Log_Notes,
  };

  return newFieldServiceLogEntry;
}

const state = () => ({
  projects: {},
  allTrackers: [],
  allGeneratorTrackers: [],
  geofences: {},
  siteConfigs: {},
  fieldServiceLogs: {},
  mapboxKey: ''
})

const actions = {
  async updateMapboxKey ({ commit, rootState }) {
    const mapboxKeyObject = await bigBrotherDb.getMapboxKey(rootState.token)
    commit('updateMapboxKey', {
      mapboxApiKey: mapboxKeyObject.mapboxApiKey
    })
  },
  async initializeProjects ({dispatch, commit, state, rootState }) {
    if (rootState.admin) {
      await dispatch('initializeSiteConfigs')
      await dispatch('updateGeofences')
      await dispatch('updateAllGeneratorTrackers')
      const dbTrackersAll = await bigBrotherDb.getAllSpytecTrackers(rootState.token)
      const globalProject = initializeGlobalProject(dbTrackersAll)
  
      commit('addProject', {
        project: globalProject
      })

      const dbProjectList = await bigBrotherDb.getAllProjects(rootState.token)
      for (let dbProject of dbProjectList) {
        const project = await initializeProject(dbProject, state, rootState.token)
        commit('addProject', {
          project: project
        })
      }
    } else {
      await dispatch('initializeSiteConfigs')
      await dispatch('updateAllGeneratorTrackers')
      const currentProject = await bigBrotherDb.getProjectById (rootState.currentProjectId, rootState.token)
      const projectGeofenceIdList = currentProject.geofences
      projectGeofenceIdList.push(currentProject.onSiteGeofence)
      await dispatch('syncProjectGeofences', projectGeofenceIdList)
      const project = await initializeProject(currentProject, state, rootState.token)
      commit('addProject', {
        project: project
      })
    }
  },
  async addNewProjectDb ({ rootState }, newProject) {
    bigBrotherDb.addNewProject(newProject, rootState.token)
  },
  async updateProjectDb ({ rootState }, project) {
    bigBrotherDb.updateProject(project, rootState.token)
  },
  async updateAllTrackers({ commit, rootState }) {
    const allTrackersDb = await bigBrotherDb.getAllSpytecTrackers(rootState.token)
    commit('updateAllTrackers', {
      allTrackers: allTrackersDb
    })
  },
  async getAllTrackersNoSave({ rootState }) {
    return await bigBrotherDb.getAllSpytecTrackers(rootState.token)
  },
  async getAllGeofencesNoSave({ rootState }) {
    return await bigBrotherDb.getAllGeofences(rootState.token)
  },
  async getProjectByIdNoSave({ rootState }, projectId) {
    return await bigBrotherDb.getProjectById(projectId, rootState.token)
  },
  async getAllSiteConfigsNoSave({ rootState }) {
    return await bigBrotherDb.getAllSiteConfigs(rootState.token)
  },
  async updateAllGeneratorTrackers({ commit, rootState }) {
    const allGeneratorTrackersDb = await bigBrotherDb.getAllGeneratorTrackers(rootState.token)
    commit('updateAllGeneratorTrackers', {
      allGeneratorTrackers: allGeneratorTrackersDb
    })
  },
  async initializeSiteConfigs({ commit, state, rootState }) {
    const siteConfigsAll = await bigBrotherDb.getAllSiteConfigs(rootState.token)
    for (let siteConfig of siteConfigsAll) {
      commit('addSiteConfig', {
        siteConfig: siteConfig
      })
    }
  },
  async addSiteConfig ({ rootState }, siteConfig) {
    if (rootState.admin) {
      return await bigBrotherDb.addSiteConfig(siteConfig, rootState.token)
    } else {
      return "Not Admin"
    }
  },
  async updateSiteConfig({ rootState }, siteConfig) {
    if (rootState.admin) {
      return await bigBrotherDb.updateSiteConfig(siteConfig, rootState.token)
    } else {
      return "Not Admin"
    }
  },
  async deleteSiteconfig({ rootState }, siteConfigId) {
    if (rootState.admin) {
      return await bigBrotherDb.deleteSiteConfig(siteConfigId, rootState.token)
    } else {
      return "Not Admin"
    }
  },
  async updateGeofences({ commit, rootState }) {
    const geofences = await bigBrotherDb.getAllGeofences(rootState.token)
    commit('updateGeofences', {
      geofences: geofences
    })
  },
  async syncProjectGeofences({ commit, rootState, state}, idList) {
    const geofences = await bigBrotherDb.getGeofencesByIds(idList, rootState.token)
    commit('updateGeofences', {
      geofences: geofences
    })
  },
  async updateGeofence({ rootState }, updatedGeofence) {
    if (rootState.admin) {
      return await bigBrotherDb.updateGeofence(updatedGeofence, rootState.token)
    } else {
      return "Not Admin"
    }
  },
  async addNewGeofence({ commit, rootState }, newGeofence) {
    bigBrotherDb.addNewGeofence(newGeofence, rootState.token)
  },

  async deleteGeofence({ commit }, id) {
    bigBrotherDb.deleteGeofence(id, this.state.token).then(_ => {
      bigBrotherDb.getAllGeofences(this.state.token).then(response => {
        commit('updateGeofences', {
          geofences: response
        })
      })
    })
  },
  async initializeFieldServiceLogs ({ commit, state, rootState }) {
    bigBrotherDb.getAllFieldServiceLogs(rootState.token).then(logs => {
      for (let log of logs) {
        commit('addFieldServiceLog', log)
      }
    })
  },
  async addFieldServiceLog({ rootState }, log) {
    bigBrotherDb.addFieldServiceLogEntry(log, rootState.token)
  },
  async updateFieldServiceLog({ rootState }, log) {
    bigBrotherDb.editFieldServiceLogEntry(log, rootState.token)
  },
  async clearAllIntervals ({ commit, state, rootState}) {
    for (let project of state.projects) {
      for (let queryIntervalId of project.queryIntervalIds) {
        window.clearInterval(queryIntervalId)
      }
      commit('resetQueryIntervalsProject', project.projectId)
    }
  },
  async clearAllIntervalsProject ({ commit, state }, projectId) {
      for (let queryIntervalId of state.projects[projectId].queryIntervalIds) {
        window.clearInterval(queryIntervalId)
      }
      commit('resetQueryIntervalsProject', projectId)
  },
  async deleteProject ({ dispatch, commit, state, rootState }, id) {
    dispatch('clearAllIntervals').then(_ => {
      bigBrotherDb.deleteProject(id, rootState.token).then(_ => {
        bigBrotherDb.getAllProjects(rootState.token).then(response => {
          commit('updateProjects', {
            projects: response
          })
        })
      })
    })
  },
  async getNewProjectMessages ({ commit, state, rootState }, { projectId }) {
    if (state.projects[projectId].lastQueriedDates.length === 0) {
      // means this has never been queried before, set up dates initially
      const end = new Date()
      // const start = new Date(state.projects[projectId].startDate)
      const start = new Date()
      start.setHours(end.getHours() - 24)

      commit('updateProjectQueryDates', {
        projectId: projectId,
        lastQueriedDates: [start, end]
      })
    } else {
      // means this has been queried before, update query params
      let newStart = state.projects[projectId].lastQueriedDates[1]

      // get 60 minutes before last end to ensure we have all the data
      newStart.setMinutes(newStart.getMinutes() - 60)

      if (newStart < state.projects[projectId].startDate) {
        // if 60 minutes before puts it before the start date, then set it to the start date again
        newStart = new Date(state.projects[projectId].startDate)
      }

      const newEnd = new Date()
      commit('updateProjectQueryDates', {
        projectId: projectId,
        lastQueriedDates: [newStart, newEnd]
      })
    }
    const start = state.projects[projectId].lastQueriedDates[0]
    const end = state.projects[projectId].lastQueriedDates[1]

    const projectStart = state.projects[projectId].startDate
    const imeiList = state.projects[projectId].trackerImeis

    const generatorDeviceIdList = []
    if (state.projects[projectId].siteConfig != null) {
      state.projects[projectId].siteConfig.Field_Sites.forEach(fieldSite => {
        fieldSite.Devices.forEach(device => {
          if (Object.keys(device).includes('Remote_Reporting_Device_Id')) {
            if (device.Remote_Reporting_Device_Id != null) {
              generatorDeviceIdList.push(state.allGeneratorTrackers.find(t => t._id == device.Remote_Reporting_Device_Id).device_id)
            }
          }
        })
      })
    }

    const generatorData = await bigBrotherDb.getCurrentGeneratorMessages(projectStart, end, generatorDeviceIdList, rootState.token)
    commit('updateProjectGeneratorMessages', {
      projectId: projectId,
      currentMessages: generatorData
    })

    const data = await bigBrotherDb.getProjectMessages(start, projectStart, end, imeiList, rootState.token)
    commit('updateProjectLocations', {
      projectId: projectId,
      locationsRaw: data.allLocations,
      currentLocations: data.currentLocations
    })
  }
}

const mutations = {
  updateMapboxKey (state, payload) {
    state.mapboxKey = payload.mapboxApiKey
  },
  addQueryIntervalId (state, { projectId, intervalId }) {
    state.projects[projectId].queryIntervalIds.push(intervalId)
  },
  removeQueryIntervalId(state, { projectId, intervalId}) {
    state.projects[projectId].queryIntervalIds = state.projects[projectId].queryIntervalIds.filter((value, index, array) => {
      return value !== intervalId
    })
  },
  resetQueryIntervalsProject(state, projectId) {
    state.projects[projectId].queryIntervalIds = []
  },
  updateGeofences(state, payload) {
    payload.geofences.forEach(geofence => {
      state.geofences[geofence._id] = geofence
    })
  },
  addFieldServiceLog(state, logEntry) {
    state.fieldServiceLogs[logEntry._id] = logEntry
  },
  addProject (state, { project }) {
    state.projects[project.projectId] = project
  },
  updateProjectQueryDates (state, { projectId, lastQueriedDates }) {
    state.projects[projectId].lastQueriedDates = lastQueriedDates
  },
  updateProjects (state, payload) {
    state.projects = payload.projects
  },
  updateAllTrackers (state, payload) {
    state.allTrackers = payload.allTrackers
  },
  updateAllGeneratorTrackers (state, payload) {
    state.allGeneratorTrackers = payload.allGeneratorTrackers
  },
  addSiteConfig (state, { siteConfig }) {
    state.siteConfigs[siteConfig._id] = siteConfig
  },
  updateProjectGeneratorMessages (state, { projectId, currentMessages }) {
    state.projects[projectId].currentGeneratorMessages = currentMessages
  },
  updateProjectLocations (state, { projectId, locationsRaw, currentLocations }) {
    state.projects[projectId].lastUpdated = new Date()

    state.projects[projectId].currentLocations = currentLocations

    const locations = []

    locationsRaw.forEach(location => {
      if (!state.projects[projectId].allMessageIdsProcessed.has(location._id)) {
        locations.push(location)
        state.projects[projectId].trackers[location.imei].locations.push(location)
        state.projects[projectId].allMessageIdsProcessed.add(location._id)
      }
    })

    // locations.forEach(location => {
    //   state.projects[projectId].trackers[location.imei].locations.push(location)
    // })

    currentLocations.forEach(currentLocation => {
      state.projects[projectId].trackers[currentLocation._id].currentLocation = currentLocation
    })

    // reset all geofence tracker lists
    Object.values(state.projects[projectId].geofences).forEach(geofence => {
      geofence.currentTrackers = []
    })

    // calculate events and times inside geofences
    locations.forEach(message => {
      Object.values(state.projects[projectId].geofences).forEach(geofence => {
        const inside = isInside(message.location, geofence)

        if (state.projects[projectId].trackers[message.imei].status[geofence.id].time == null) {
          state.projects[projectId].trackers[message.imei].status[geofence.id].time = new Date(message.gpsUTCTime)
          state.projects[projectId].trackers[message.imei].status[geofence.id].inside = inside

          state.projects[projectId].geofences[geofence.id].status[message.imei].time = new Date(message.gpsUTCTime)
          state.projects[projectId].geofences[geofence.id].status[message.imei].inside = inside
        } else {
          if (inside) {
            if (state.projects[projectId].trackers[message.imei].status[geofence.id].inside) {
              // means was inside and currently inside, do nothing
            } else {
              // means was outside and currently is inside, set status to inside
              state.projects[projectId].trackers[message.imei].status[geofence.id].time = new Date(message.gpsUTCTime)
              state.projects[projectId].trackers[message.imei].status[geofence.id].inside = inside

              state.projects[projectId].geofences[geofence.id].status[message.imei].time = new Date(message.gpsUTCTime)
              state.projects[projectId].geofences[geofence.id].status[message.imei].inside = inside
            }
          } else {
            if (state.projects[projectId].trackers[message.imei].status[geofence.id].inside) {
              // means was inside and currently is outside, add event

              const inTime = state.projects[projectId].trackers[message.imei].status[geofence.id].time
              const inTimeFormatted = dateHelp.displayFromObj(inTime)
              const outTime = new Date(message.gpsUTCTime)
              const outTimeFormatted = dateHelp.displayFromObj(outTime)
              const timeInside = outTime - inTime
              const timeInsideFormatted = dateHelp.displayFromMs(timeInside)

              state.projects[projectId].trackers[message.imei].events.push({
                geofenceId: geofence.id,
                geofenceName: geofence.name,
                inTime: inTime,
                inTimeFormatted: inTimeFormatted,
                outTime: outTime,
                outTimeFormatted: outTimeFormatted,
                timeInside: timeInside,
                timeInsideFormatted: timeInsideFormatted
              })

              // update total time
              state.projects[projectId].trackers[message.imei].fenceTotals[geofence.id].totalTime += timeInside

              geofence.events.push({
                trackerImei: state.projects[projectId].trackers[message.imei].imei,
                trackerName: state.projects[projectId].trackers[message.imei].name,
                inTime: inTime,
                inTimeFormatted: inTimeFormatted,
                outTime: outTime,
                outTimeFormatted: outTimeFormatted,
                timeInside: timeInside,
                timeInsideFormatted: timeInsideFormatted
              })

              // update total time
              geofence.trackerTotals[state.projects[projectId].trackers[message.imei].imei].totalTime += timeInside

              state.projects[projectId].trackers[message.imei].status[geofence.id].time = new Date(message.gpsUTCTime)
              state.projects[projectId].trackers[message.imei].status[geofence.id].inside = inside

              state.projects[projectId].geofences[geofence.id].status[message.imei].time = new Date(message.gpsUTCTime)
              state.projects[projectId].geofences[geofence.id].status[message.imei].inside = inside
            } else {
              // means was outside and is still outside, do nothing
            }
          }
        }
      })
    })

    // calculate which tracker is in which geofence
    Object.values(state.projects[projectId].trackers).forEach(tracker => {
      tracker.currentGeofences = [] // reset tracker geofence list
      tracker.onSite = false
      if (tracker.currentLocation != null) {
        Object.values(state.projects[projectId].geofences).forEach(geofence => {
          geofence.fences.forEach(fence => {
            if (turf.booleanPointInPolygon(tracker.currentLocation.location, fence)) {
              tracker.currentGeofences.push({
                name: geofence.name,
                id: geofence.id
              })
              geofence.currentTrackers.push({
                name: tracker.name,
                id: tracker.id,
                imei: tracker.imei,
                enterTime: state.projects[projectId].geofences[geofence.id].status[tracker.imei].time
              })
            }
          })
        })
      }
    })

    // // handle fences that tracker hasn't left yet
    // Object.values(state.projects[projectId].trackers).forEach(tracker => {
    //   Object.values(tracker.status).forEach(fenceStatus => {
    //     if (fenceStatus.inside) {
    //       // means tracker is still inside a fence, add event
    //       const geofence = state.projects[projectId].geofences[fenceStatus.fenceId]
    //       const inTime = fenceStatus.time
    //       const inTimeFormatted = dateHelp.displayFromObj(inTime)
    //       let outTime = null

    //       if (state.projects[projectId].endDate > state.projects[projectId].lastUpdated) {
    //         outTime = state.projects[projectId].lastUpdated
    //       } else {
    //         outTime = state.projects[projectId].endDate
    //       }

    //       const outTimeFormatted = dateHelp.displayFromObj(outTime) + '(Ended Inside)'
    //       const timeInside = outTime - inTime
    //       const timeInsideFormatted = dateHelp.displayFromMs(timeInside)

    //       tracker.events.push({
    //         geofenceId: geofence.id,
    //         geofenceName: geofence.name,
    //         inTime: inTime,
    //         inTimeFormatted: inTimeFormatted,
    //         outTime: outTime,
    //         outTimeFormatted: outTimeFormatted,
    //         timeInside: timeInside,
    //         timeInsideFormatted: timeInsideFormatted
    //       })

    //       // update total time
    //       tracker.fenceTotals[geofence.id].totalTime += timeInside

    //       state.projects[projectId].geofences[fenceStatus.fenceId].events.push({
    //         trackerImei: tracker.imei,
    //         trackerName: tracker.name,
    //         inTime: inTime,
    //         inTimeFormatted: inTimeFormatted,
    //         outTime: outTime,
    //         outTimeFormatted: outTimeFormatted,
    //         timeInside: timeInside,
    //         timeInsideFormatted: timeInsideFormatted
    //       })

    //       state.projects[projectId].geofences[fenceStatus.fenceId].trackerTotals[tracker.imei].totalTime += timeInside
    //     }
    //   })
    // })

    state.projects[projectId].newDataLoaded++ // signals there is new data, redraw the map
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
