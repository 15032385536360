// const router = require('@/router/index')
// const store = require('@/store/index')

const axios = require('axios')
const { default: jwtDecode } = require('jwt-decode')

function handleError (error) {
  // if (error.response.status === '401') {
  //   router.default.push('/')
  // }
  return Promise.reject(error)
}

axios.interceptors.response.use(response => {
  return response
}, handleError)

// FIXME: Come up with a better solution for this
const apiUrl = 'https://api.moranstatus.com'
// const apiUrl =  'http://192.168.20.15'
// const apiUrl = 'http://localhost:3000'

async function login (username, password) {
  const data = await axios({
    url: apiUrl + '/authenticateUser',
    method: 'POST',
    data: {
      username: username,
      password: password
    }
  }).catch(_ => {
    // console.log(error.response)

    return false
  })

  if (data === false) {
    return {
      login: false,
      token: '',
      username: '',
      roles: [],
      currentProjectId: ''
    }
  } else {
    const decodedToken = jwtDecode(data.data.token)
    return {
      login: true,
      token: data.data.token,
      username: decodedToken.user,
      roles: decodedToken.roles,
      currentProjectId: data.data.currentProjectId
    }
  }
}

async function logout(token) {
  const data = await axios({
    url: apiUrl + '/logout',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  }).catch(error => {
    console.log(error)
  })

  // FIXME: add some checks to make sure request came back ok
  return data
}

async function getAllProjects (token) {
  const data = await axios({
    url: apiUrl + '/allProjects',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  }).catch(error => {
    console.log(error)
  })

  // FIXME: add some checks to make sure request came back ok
  return data.data
}

async function getProjectById (id, token) {
  const data = await axios({
    url: apiUrl + '/project',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  // FIXME: add some checks to make sure request came back ok
  return data.data
}

async function getAllSpytecTrackers (token) {
  const data = await axios({
    url: apiUrl + '/allSpytecTrackers',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })

  // FIXME: add some checks to make sure request came back ok
  return data.data
}

async function addNewProject (project, token) {
  const data = await axios({
    url: apiUrl + '/addNewProject',
    method: 'POST',
    data: project,
    headers: { authorization: 'Bearer ' + token }
  })

  return data
}

async function updateProject (project, token) {
  const data = await axios({
    url: apiUrl + '/updateProject',
    method: 'POST',
    data: project,
    headers: { authorization: 'Bearer ' + token }
  })

  return data
}

async function deleteProject (id, token) {
  const data = await axios({
    url: apiUrl + '/deleteProject',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function getAllGeofences (token) {
  const data = await axios({
    url: apiUrl + '/allGeofences',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function getGeofenceById (id, token) {
  const data = await axios({
    url: apiUrl + '/geofence',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  // FIXME: add some checks to make sure request came back ok
  return data.data
}

async function getGeofencesByIds (idList, token) {
  const data = await axios({
    url: apiUrl + '/geofences',
    method: 'POST',
    data: {
      idList: idList
    },
    headers: { authorization: 'Bearer ' + token }
  })

  // FIXME: add some checks to make sure request came back ok
  return data.data
}

async function addNewGeofence (geofence, token) {
  const data = await axios({
    url: apiUrl + '/addNewGeofence',
    method: 'POST',
    data: geofence,
    headers: { authorization: 'Bearer ' + token }
  })

  return data
}

async function updateGeofence (geofence, token) {
  const data = await axios({
    url: apiUrl + '/updateGeofence',
    method: 'POST',
    data: geofence,
    headers: { authorization: 'Bearer ' + token }
  })

  return data
}

async function deleteGeofence (id, token) {
  const data = await axios({
    url: apiUrl + '/deleteGeofence',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function lastDayAllMessages (token) {
  const start = new Date()
  const end = new Date()
  start.setDate(start.getDate() - 1)

  const data = await axios({
    url: apiUrl + '/messagesBetween',
    method: 'POST',
    data: {
      startDate: start,
      endDate: end
    },
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getProjectMessages (start, projectStart, end, imeiList, token) {
  const data = await axios({
    url: apiUrl + '/messagesBetweenImei',
    method: 'POST',
    data: {
      startDate: start,
      projectStartDate: projectStart,
      endDate: end,
      imeiList
    },
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getCurrentLocations (projectStart, end, imeiList, token) {
  const data = await axios({
    url: apiUrl + '/currentLocations',
    method: 'POST',
    data: {
      projectStartDate: projectStart,
      endDate: end,
      imeiList
    },
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getCurrentSensorData(token) {
  const data = await axios({
    url: apiUrl + '/currentSensorData',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function getSensorStatus(start, end, imei, token) {
  const data = await axios({
    url: apiUrl + '/sensorData',
    method: 'POST',
    data: {
      startDate: start,
      endDate: end,
      imei: imei
    },
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getMapboxKey (token) {
  const data = await axios({
    url: apiUrl + '/initiateMapbox',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function getPowerTrailerStatus (start, end, trailerId, token) {
  const data = await axios({
    url: apiUrl + '/trailerPowerData',
    method: 'POST',
    data: {
      startDate: start,
      endDate: end,
      trailerId: trailerId
    },
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getTrailerIds (token) {
  const data = await axios({
    url: apiUrl + '/trailerIds',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getPowerTrailerSummary (token) {
  const data = await axios({
    url: apiUrl + '/trailerPowerDataSummary',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getPiMonitorStatus (start, end, monitorId, token) {
  const data = await axios({
    url: apiUrl + '/piMonitorData',
    method: 'POST',
    data: {
      startDate: start,
      endDate: end,
      monitorId: monitorId
    },
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getPiMonitorIds (token) {
  const data = await axios({
    url: apiUrl + '/piMonitorIds',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getPiMonitorSummary (token) {
  const data = await axios({
    url: apiUrl + '/piMonitorDataSummary',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getWeatherSummary (token) {
  const data = await axios({
    url: apiUrl + '/weatherSummary',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getWeatherData (start, end, stationId, type, token) {
  const data = await axios({
    url: apiUrl + '/weatherData',
    method: 'POST',
    data: {
      startDate: start,
      endDate: end,
      stationId: stationId,
      type: type
    },
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getAllSiteConfigs(token) {
  const data = await axios({
    url: apiUrl + '/allSiteConfigs',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  }).catch(error => {
    console.log(error)
  })

  return data.data
}

async function getSiteConfig(id, token) {
  const data = await axios({
    url: apiUrl + '/siteConfig',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function addSiteConfig(entry, token) {
  const data = await axios({
    url: apiUrl + '/addSiteConfig',
    method: 'POST',
    data: entry,
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function updateSiteConfig(entry, token) {
  const data = await axios({
    url: apiUrl + '/updateSiteConfig',
    method: 'POST',
    data: entry,
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function deleteSiteConfig(id, token) {
  const data = await axios({
    url: apiUrl + '/deleteSiteConfig',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function getAllFieldServiceLogs (token) {
  const data = await axios({
    url: apiUrl + '/allFieldServiceLogs',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  }).catch(error => {
    console.log(error)
  })

  return data.data
}

async function getFieldServiceLogEntry (id, token) {
  const data = await axios({
    url: apiUrl + '/FieldServiceLogEntry',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function addFieldServiceLogEntry (entry, token) {
  const data = await axios({
    url: apiUrl + '/addFieldServiceLogEntry',
    method: 'POST',
    data: entry,
    headers: { authorization: 'Bearer ' + token }
  })

  return data
}

async function editFieldServiceLogEntry (entry, token) {
  const data = await axios({
    url: apiUrl + '/updateFieldServiceLogEntry',
    method: 'POST',
    data: entry,
    headers: { authorization: 'Bearer ' + token }
  })

  return data
}

async function deleteFieldServiceLogEntry(id, token) {
  const data = await axios({
    url: apiUrl + '/deleteFieldServiceLogEntry',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function getAllGeneratorTrackers(token) {
  const data = await axios({
    url: apiUrl + '/allGeneratorTrackers',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  }).catch(error => {
    console.log(error)
  })

  return data.data
}

async function getCurrentGeneratorMessages(projectStart, end, deviceIdList, token) {
  const data = await axios({
    url: apiUrl + '/currentGeneratorMessagesId',
    method: 'POST',
    data: {
      projectStartDate: projectStart,
      endDate: end,
      deviceIdList: deviceIdList
    },
    headers: { authorization: 'Bearer ' + token }
  })
  return data.data
}

async function getAllCorkboardMessages (token) {
  const data = await axios({
    url: apiUrl + '/allCorkboardMessages',
    method: 'POST',
    headers: { authorization: 'Bearer ' + token }
  }).catch(error => {
    console.log(error)
  })

  return data.data
}

async function getCorkboardMessage (id, token) {
  const data = await axios({
    url: apiUrl + '/corkboardMessage',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

async function addCorkboardMessage (entry, token) {
  const data = await axios({
    url: apiUrl + '/addCorkboardMessage',
    method: 'POST',
    data: entry,
    headers: { authorization: 'Bearer ' + token }
  })

  return data
}

async function editCorkboardMessage (entry, token) {
  const data = await axios({
    url: apiUrl + '/updateCorkboardMessage',
    method: 'POST',
    data: entry,
    headers: { authorization: 'Bearer ' + token }
  })

  return data
}

async function deleteCorkboardMessage(id, token) {
  const data = await axios({
    url: apiUrl + '/deleteCorkboardMessage',
    method: 'POST',
    data: {
      id: id
    },
    headers: { authorization: 'Bearer ' + token }
  })

  return data.data
}

module.exports = {
  login,
  logout,
  getAllProjects,
  getProjectById,
  getAllSpytecTrackers,
  addNewProject,
  updateProject,
  deleteProject,
  getAllGeofences,
  getGeofenceById,
  getGeofencesByIds,
  addNewGeofence,
  updateGeofence,
  deleteGeofence,
  lastDayAllMessages,
  getProjectMessages,
  getCurrentLocations,
  getCurrentSensorData,
  getSensorStatus,
  getMapboxKey,
  getPowerTrailerStatus,
  getTrailerIds,
  getPowerTrailerSummary,
  getPiMonitorStatus,
  getPiMonitorIds,
  getPiMonitorSummary,
  getWeatherSummary,
  getWeatherData,
  getAllSiteConfigs,
  getSiteConfig,
  addSiteConfig,
  updateSiteConfig,
  deleteSiteConfig,
  addFieldServiceLogEntry,
  getFieldServiceLogEntry,
  getAllFieldServiceLogs,
  addFieldServiceLogEntry,
  editFieldServiceLogEntry,
  deleteFieldServiceLogEntry,
  getAllGeneratorTrackers,
  getCurrentGeneratorMessages,
  getAllCorkboardMessages,
  getCorkboardMessage,
  addCorkboardMessage,
  editCorkboardMessage,
  deleteCorkboardMessage
}
