import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import store from '../store/index'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard/:id',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/projects',
    name: 'Projects',
    component: () => import('../views/manageViews/Projects.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/newProject',
    name: 'NewProject',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/manageViews/NewProject.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/editProject/:id',
    name: 'EditProject',
    component: () => import('../views/manageViews/EditProject.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/siteConfigs',
    name: 'SiteConfigs',
    component: () => import('../views/manageViews/SiteConfigs.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/newSiteConfig',
    name: "NewSiteConfig",
    component: () => import('../views/manageViews/NewSiteConfig.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/editSiteConfig/:id',
    name: "EditSiteConfig",
    component: () => import('../views/manageViews/EditSiteConfig.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/fieldServiceLog',
    name: 'FieldServiceLog',
    component: () => import('../views/manageViews/FieldServiceLogs.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/newFieldServiceLog',
    name: 'NewFieldServiceLog',
    component: () => import('../views/manageViews/NewFieldServiceLog.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/editFieldServiceLog',
    name: 'EditFieldServiceLog',
    component: () => import('../views/manageViews/EditFieldServiceLog.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/FieldServiceLogDetails',
    name: 'FieldServiceLogDetails',
    component: () => import('../views/manageViews/FieldServiceLogDetails.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/geofences',
    name: 'Geofences',
    component: () => import('../views/manageViews/Geofences.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/newGeofence',
    name: 'NewGeofence',
    component: () => import('../views/manageViews/NewGeofence.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/manage/editGeofence/:id',
    name: 'EditGeofence',
    component: () => import('../views/manageViews/EditGeofence.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/manage/corkboard",
    name: "Corkboard",
    component: () => import("../views/manageViews/Corkboard.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/manage/editCorkboardMessage",
    name: "EditCorkboardMessage",
    component: () => import("../views/manageViews/EditCorkboardMessage.vue"),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/globalMap',
    name: 'GlobalLiveMap',
    component: () => import('../views/GlobalLiveMap.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/project/liveMap/:projectId',
    name: 'ProjectLiveMap',
    component: () => import('../views/project/ProjectLiveMap.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/project/replay/:projectId',
    name: 'ReplayView',
    component: () => import('../views/project/ReplayView.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/project/status/:projectId',
    name: 'ProjectStatus',
    component: () => import('../views/project/ProjectStatus.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/project/report/:projectId',
    name: 'ReportView',
    component: () => import('../views/project/ReportView.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/monitors/powertrailerstatus',
    name: 'PowerTrailerStatus',
    component: () => import('../views/monitors/PowerTrailerStatus.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitors/powertrailer/:trailerId',
    name: 'PowerTrailerDetails',
    component: () => import('../views/monitors/PowerTrailerDetails.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/monitors/pimonitorstatus',
    name: 'PiMonitorStatus',
    component: () => import('../views/monitors/PiMonitorStatus.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitors/piMonitor/:monitorId',
    name: 'PiMonitorDetails',
    component: () => import('../views/monitors/PiMonitorDetails.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/monitors/weatherstatus',
    name: 'WeatherStatus',
    component: () => import('../views/monitors/WeatherStatus.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitors/weatherDetails/:stationId:stationType',
    name: 'WeatherDetails',
    component: () => import('../views/monitors/WeatherDetails.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/monitors/sensorstatus',
    name: 'SensorStatus',
    component: () => import('../views/monitors/SensorStatus.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/monitors/sensordetails/:imei',
    name: 'SensorDetails',
    component: () => import('../views/monitors/SensorDetails.vue'),
    props: true,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.loggedIn) {
      next()
    } else {
      console.log('Not Logged In, re-routing to login')
      next({
        path: '/'
      })
    }
  } else {
    next()
  }
})

export default router
