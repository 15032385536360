/*
  returns an ISO 8601 string from a Date and a Time parameter
*/
function ISOFromDateAndTime (date, time) {
  if (date != null &&
      time != null &&
      date !== '' &&
      time !== '') {
    return date + 'T' + time + ':00.000Z'
  } else {
    return null
  }
}

/* 
  returns a time string from an ISO 8601 string
*/
function timeFromISO (isoString) {  
  if (isoString != null && isoString !== '') {
    const split = isoString.split('T')
    const timeSplit = split[1].split(':')
    return timeSplit[0] + ':' + timeSplit[1]
  } else {
    return null
  }
}

/* 
  returns a date string from an ISO 8601 string
*/
function dateFromISO (isoString) {
  if (isoString != null && isoString !== '') {
    const split = isoString.split('T')
    return split[0]
  } else {
    return null
  }
}

/*
  returns a Date object from the string value of a
  date stored in the database which is an ISO format
*/
function dateObjFromDbDate (dbDateString) {
  if (dbDateString != null && dbDateString !== '') {
    const split = dbDateString.split('T')
    const dateSplit = split[0].split('-')
    const timeSplit = split[1].split(':')

    // doing it this way because instantiating date
    // makes it in local time, but the date in the
    // database is stored in UTC time
    const rv = new Date()
    rv.setUTCFullYear(parseInt(dateSplit[0]))
    rv.setUTCMonth(parseInt(dateSplit[1] - 1))
    rv.setUTCDate(parseInt(dateSplit[2]))
    rv.setUTCHours(parseInt(timeSplit[0]))
    rv.setUTCMinutes(parseInt(timeSplit[1]))
    rv.setUTCSeconds(parseInt(timeSplit[2]))
    rv.setUTCMilliseconds(0) // not keeping track of milliseconds

    return rv
  } else {
    return null
  }
}

/*
  returns a Date object from the string values of an
  HTML 5 input of type "date" and an HTML input of type
  "time"
*/
function dateObjFromInputs (dateString, timeString) {
  if (dateString != null &&
      dateString !== '' &&
      timeString != null &&
      timeString !== '') {
    const dateSplit = dateString.split('-')
    const timeSplit = timeString.split(':')

    return new Date(
      parseInt(dateSplit[0]), // year
      parseInt(dateSplit[1] - 1), // month
      parseInt(dateSplit[2]), // day
      parseInt(timeSplit[0]), // hour
      parseInt(timeSplit[1]) // minute
    )
  } else {
    return null
  }
}

/*
  returns a string for an HTML input of type "date"
  from a Date object
*/
function inputDateFromObj (date) {
  if (date != null) {
    return date.getFullYear().toString().padStart(4, '0') + '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
      date.getDate().toString().padStart(2, '0')
  } else {
    return ''
  }
}

/*
  returns a string for an HTML input of type "time"
  from a Date object
*/
function inputTimeFromObj (date) {
  if (date != null) {
    return date.getHours().toString().padStart(2, '0') + ':' +
          date.getMinutes().toString().padStart(2, '0')
  } else {
    return ''
  }
}

/*
  returns a display string from a Date object
*/
function displayFromObj (date) {
  if (date != null) {
    return date.toLocaleString()
  } else {
    return ''
  }
}

const numMsInDay = 86400000 // 1000 * 60 * 60 * 24
const numMsInHour = 3600000 // 1000 * 60 * 60
const numMsInMinute = 60000 // 1000 * 60
const numMsInSecond = 1000 // 1000 * 1
/*
  returns a display string from a number of millisecondss
*/
function displayFromMs (ms) {
  const maxHours = ms % numMsInDay
  const maxMinutes = ms % numMsInHour

  let days = 0
  let hours = 0
  let minutes = 0

  if (ms >= numMsInDay) {
    days = Math.floor(ms / numMsInDay)
  }
  if (maxHours >= numMsInHour) {
    hours = Math.floor(maxHours / numMsInHour)
  }
  if (maxMinutes >= numMsInMinute) {
    minutes = Math.floor(maxMinutes / numMsInMinute)
  }
  const pad = '00'
  const dayString = (pad + days).slice(-pad.length)
  const hourString = (pad + hours).slice(-pad.length)
  const minString = (pad + minutes).slice(-pad.length)

  return `${dayString}:${hourString}:${minString}`

  // if (days > 0) {
  //   return `${days} D, ${hours} H, ${minutes} M`
  // } else {
  //   if (hours > 0) {
  //     // return `${hours} H, ${minutes} M`
  //     return `${hourString}:${minString}`
  //   } else {
  //     return `${minutes} M`
  //   }
  // }
}

function displayFromMsShort (ms) {
  const maxHours = ms % numMsInDay
  const maxMinutes = ms % numMsInHour
  const maxSeconds = ms % numMsInMinute

  let days = 0
  let hours = 0
  let minutes = 0
  let seconds = 0

  if (ms >= numMsInDay) {
    days = Math.floor(ms / numMsInDay)
  }
  if (maxHours >= numMsInHour) {
    hours = Math.floor(maxHours / numMsInHour)
  }
  if (maxMinutes >= numMsInMinute) {
    minutes = Math.floor(maxMinutes / numMsInMinute)
  }
  if (maxSeconds >= numMsInSecond) {
    seconds = Math.floor(maxSeconds / numMsInSecond)
  }

  if (days > 0) {
    return `${days} D, ${hours} H, ${minutes} M, ${seconds} S`
  } else {
    if (hours > 0) {
      return `${hours} H, ${minutes} M, ${seconds} S`
    } else {
      if (minutes > 0) {
        return `${minutes} M, ${seconds} S`
      } else {
        return `${seconds} S`
      }
    }
  }
}

module.exports = {
  dateObjFromDbDate,
  dateObjFromInputs,
  inputDateFromObj,
  inputTimeFromObj,
  displayFromObj,
  displayFromMs,
  displayFromMsShort,
  ISOFromDateAndTime,
  dateFromISO,
  timeFromISO
}
