import bigBrotherDb from '@/bigBrotherApi'

const state = () => ({
  stations: {},
  statusIntervalIds: [],
  windspeedWarning: 7.0,
  windspeedDanger: 3.0
})

const getters = {
  getStationById: (state) => (stationId) => {
    return state.stations[stationId]
  }
}

const actions = {
  getNewWeatherSummary ({ commit, state, rootState }) {
    bigBrotherDb.getWeatherSummary(rootState.token).then(stationStatuses => {
      stationStatuses.forEach(status => {
        const receivedDate = new Date(status.receivedDate)
        const stationDate = new Date(status.stationDate)

        status.receivedDate = receivedDate.toLocaleDateString() + ' ' + receivedDate.toLocaleTimeString()
        status.stationDate = stationDate.toLocaleDateString() + ' ' + stationDate.toLocaleTimeString()

        // this ensures that the station exists before we try to update the status
        commit('stationCheck', {
          stationId: status.stationId,
          stationType: status.type
        })

        commit('updateStationSummary', {
          stationId: status.stationId,
          status: status
        })
      })
    })
  },
  getNewWeatherDetails: async function ({ commit, state, rootState }, { stationId, stationType }) {
    // this ensures that the station exists before we try to update the status
    commit('stationCheck', {
      stationId: stationId,
      stationType: stationType
    })

    if (state.stations[stationId].lastQueriedDates.length === 0) {
      // means this has never been queried before, set up dates initially
      const end = new Date()
      const start = new Date(end.getTime())
      // start.setMinutes(start.getMinutes() - 10)
      // FIXME: make this dynamic based on date ranges of graph
      start.setHours(start.getHours() - 168)

      commit('updateStationDates', {
        stationId: stationId,
        lastQueriedDates: [start, end]
      })
    } else {
      // means this has been queried before, update query params
      const newStart = state.stations[stationId].lastQueriedDates[1]
      // get 5 minutes before last end to ensure we have all the data
      newStart.setMinutes(newStart.getMinutes() - 5)

      const newEnd = new Date()
      commit('updateStationDates', {
        stationId: stationId,
        lastQueriedDates: [newStart, newEnd]
      })
    }
    const start = state.stations[stationId].lastQueriedDates[0]
    const end = state.stations[stationId].lastQueriedDates[1]

    const data = await bigBrotherDb.getWeatherData(start, end, stationId, stationType, rootState.token)
    const realDataToAdd = []
    let pointsToCheck = 10
    if (state.stations[stationId].specificData.length < 10) {
      pointsToCheck = state.stations[stationId].specificData.length
    }

    data.forEach(newPoint => {
      let found = false
      for (let i = 0; i < pointsToCheck; i++) {
        if (newPoint.receivedDate === state.stations[stationId].specificData[i].receivedDate) {
          found = true
        }
      }
      if (found === false) {
        realDataToAdd.push(newPoint)
      }
    })

    commit('addNewSpecificData', {
      stationId: stationId,
      dataToAdd: realDataToAdd
    })
    return 'success'
  }
}

const mutations = {
  stationCheck (state, { stationId, stationType }) {
    if (!Object.prototype.hasOwnProperty.call(state.stations, stationId)) {
      // means the station hasn't been registered, register it now
      const newStation = {
        stationId: stationId,
        status: null,
        stationType: stationType,
        specificData: [],
        lastQueriedDates: [],
        whatToGraph: 'windSpeed',
        dateRangeToGraph: 'hour24',
        showGraphPoints: true,
        detailsIntervalIds: []
      }
      state.stations[stationId] = newStation
    }
  },
  updateStationSummary (state, { stationId, status }) {
    state.stations[stationId].status = status
  },
  updateStationDates (state, { stationId, lastQueriedDates }) {
    state.stations[stationId].lastQueriedDates = lastQueriedDates
  },
  addNewSpecificData (state, { stationId, dataToAdd }) {
    state.stations[stationId].specificData.unshift(...dataToAdd)
  },
  updateWhatToGraph (state, { stationId, whatToGraph }) {
    state.stations[stationId].whatToGraph = whatToGraph
  },
  updateDateRangeToGraph (state, { stationId, dateRangeToGraph }) {
    state.stations[stationId].dateRangeToGraph = dateRangeToGraph
  },
  updateShowGraphPoints (state, { stationId, showGraphPoints }) {
    state.stations[stationId].showGraphPoints = showGraphPoints
  },
  addDetailsIntervalId (state, { stationId, intervalId }) {
    state.stations[stationId].detailsIntervalIds.push(intervalId)
  },
  addStatusIntervalId (state, intervalId) {
    state.statusIntervalIds.push(intervalId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
