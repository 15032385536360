import { createStore } from 'vuex'
import projectStore from './modules/projectStore'
import weatherStations from './modules/weatherStations'
import piMonitors from './modules/piMonitors'
import powerTrailers from './modules/powerTrailers'
import sensors from './modules/sensors'
import fieldServiceLogStore from './modules/fieldServiceLogStore'
const bigBrotherDb = require('@/bigBrotherApi')

export default createStore({
  state: {
    loggedIn: false,
    username: '',
    roles: [],
    token: '',
    fieldServiceLogEntries: [],
    admin: false,
    currentProjectId: ''
  },
  mutations: {
    toggleAdmin () {
      if (admin) {
        admin = false
      } else {
        admin = true
      }
    },
    updateLogin (state, payload) {
      state.loggedIn = payload.loggedIn
      state.token = payload.token
      state.username = payload.username,
      state.roles = payload.roles,
      state.currentProjectId = payload.currentProjectId
      if (payload.roles.includes('admin')) {
        state.admin = true
      } else {
        state.admin = false
      }
    },
    updateFieldServiceLogEntries (state, payload) {
      state.fieldServiceLogEntries = payload.fieldServiceLogEntries
    }
  },
  actions: {
    async updateFieldServiceLogEntries ({ commit }) {
      const fieldServiceLogEntries = await bigBrotherDb.getAllFieldServiceLogs(this.state.token)
      commit('updateFieldServiceLogEntries', {
        fieldServiceLogEntries: fieldServiceLogEntries
      })
    },
    async deleteFieldServiceLogEntry ({ commit }, id) {
      bigBrotherDb.deleteFieldServiceLogEntry(id, this.state.token).then(_ => {
        bigBrotherDb.getAllFieldServiceLogs(this.state.token).then(response => {
          commit('updateFieldServiceLogEntries', {
            fieldServiceLogEntries: response
          })
        })
      })
    }
  },
  modules: {
    projectStore,
    weatherStations,
    piMonitors,
    powerTrailers,
    sensors,
    fieldServiceLogStore,
  }
})
