import bigBrotherDb from '@/bigBrotherApi'

const state = () => ({
  monitors: {},
  statusIntervalIds: []
})

const actions = {
  getNewPiMonitorStatus ({ commit, state, rootState }) {
    bigBrotherDb.getPiMonitorSummary(rootState.token).then(monitorStatuses => {
      monitorStatuses.forEach(status => {
        // this ensures that the monitor exists before we try to update the status
        commit('monitorCheck', status.Monitor_Id)

        commit('updateMonitorStatus', {
          monitorId: status.Monitor_Id,
          status: status
        })
      })
    })
  },
  getNewPiMonitorDetails: async function ({ commit, state, rootState }, { monitorId }) {
    // this ensures that the station exists before we try to update the details
    commit('monitorCheck', monitorId)

    if (state.monitors[monitorId].lastQueriedDates.length === 0) {
      // means this has never been queried before, set up dates initially
      const end = new Date()
      const start = new Date(end.getTime())
      // FIXME: make this dynamic based on date ranges of graph
      start.setHours(start.getHours() - 168)

      commit('updateMonitorDates', {
        monitorId: monitorId,
        lastQueriedDates: [start, end]
      })
    } else {
      // means this has been queried before, update query params
      const newStart = state.monitors[monitorId].lastQueriedDates[1]
      // get 5 minutes before last end to ensure we have all the data
      newStart.setMinutes(newStart.getMinutes() - 5)

      const newEnd = new Date()
      commit('updateMonitorDates', {
        monitorId: monitorId,
        lastQueriedDates: [newStart, newEnd]
      })
    }
    const start = state.monitors[monitorId].lastQueriedDates[0]
    const end = state.monitors[monitorId].lastQueriedDates[1]

    const data = await bigBrotherDb.getPiMonitorStatus(start, end, monitorId, rootState.token)
    const realDataToAdd = []
    let pointsToCheck = 10
    if (state.monitors[monitorId].specificData.length < 10) {
      pointsToCheck = state.monitors[monitorId].specificData.length
    }

    data.forEach(newPoint => {
      let found = false
      for (let i = 0; i < pointsToCheck; i++) {
        if (newPoint.Time_Received === state.monitors[monitorId].specificData[i].Time_Received) {
          found = true
        }
      }
      if (found === false) {
        realDataToAdd.push(newPoint)
      }
    })

    commit('addNewSpecificData', {
      monitorId: monitorId,
      dataToAdd: realDataToAdd
    })
    return 'success'
  }
}

const mutations = {
  monitorCheck (state, monitorId) {
    if (!Object.prototype.hasOwnProperty.call(state.monitors, monitorId)) {
      // means the monitor hasn't been registered, register it now
      const newMonitor = {
        monitorId: monitorId,
        status: null,
        specificData: [],
        lastQueriedDates: [],
        whatToGraph: 'battPercent',
        dateRangeToGraph: 'week1',
        showGraphPoints: true,
        detailsIntervalIds: []
      }
      state.monitors[monitorId] = newMonitor
    }
  },
  updateMonitorStatus (state, { monitorId, status }) {
    state.monitors[monitorId].status = status
  },
  updateMonitorDates (state, { monitorId, lastQueriedDates }) {
    state.monitors[monitorId].lastQueriedDates = lastQueriedDates
  },
  addNewSpecificData (state, { monitorId, dataToAdd }) {
    state.monitors[monitorId].specificData.unshift(...dataToAdd)
  },
  updateWhatToGraph (state, { monitorId, whatToGraph }) {
    state.monitors[monitorId].whatToGraph = whatToGraph
  },
  updateDateRangeToGraph (state, { monitorId, dateRangeToGraph }) {
    state.monitors[monitorId].dateRangeToGraph = dateRangeToGraph
  },
  updateShowGraphPoints (state, { monitorId, showGraphPoints }) {
    state.monitors[monitorId].showGraphPoints = showGraphPoints
  },
  addDetailsIntervalId (state, { monitorId, intervalId }) {
    state.monitors[monitorId].detailsIntervalIds.push(intervalId)
  },
  addStatusIntervalId (state, intervalId) {
    state.statusIntervalIds.push(intervalId)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
